<template>
  <div class="email-not-received mt-3">
    <div v-if="tryWith === 'email'">
      <p>
        Tente procurar o e-mail de verificação na sua caixa de spam. Se ele não estiver lá, entre em contato com o administrador da sua plataforma e solicite a
        troca do seu endereço de e-mail.
      </p>
      <p>
        Em último caso entre em contato com:<br />
        suporte@mercafacil.com
      </p>
      <div class="link-container mt-10">
        <span class="link" @click="$emit('email-received')">Recebi meu e-mail</span>
      </div>
      <div v-if="hiddenPhone" class="link-container d-flex justify-center">
        <mf-button class="mt-5 unset-capitalize" color="marca-0-l10" label="Tentar por SMS" :loading="loading" outlined @click="trySms" />
      </div>
    </div>

    <div v-if="tryWith === 'sms'">
      <p v-if="step" class="password-recover-description text-center my-8" v-html="description" />

      <div v-if="step === 'try-sms'">
        <v-menu v-model="notMyPhone" :close-on-content-click="false" content-class="not-my-phone-card text-center py-6 px-3" max-width="400" nudge-top="40" top>
          <template v-slot:activator="{ on }">
            <div class="text-center mb-4">
              <span class="py-2 px-4 link" v-on="on">Esse não é meu telefone</span>
            </div>
          </template>
          <div>
            <p class="mb-4">
              Entre em contato com o administrador da sua plataforma para verificar seus dados e solicitar a verificação do seu dispositivo de acesso à
              plataforma Mercafacil.
            </p>
            <p>Em último caso entre em contato com: suporte@mercafacil.com</p>
            <mf-button
              class="mt-4 unset-capitalize"
              color="marca-0-l10"
              label="Fechar"
              :loading="loading"
              outlined
              @click="notMyPhone = false"
              @keydown.enter="handleClickButton"
            />
          </div>
        </v-menu>

        <div>
          <v-text-field
            v-model="phone"
            v-mask="['+55 (##) #####-####', '+55 (##) ####-####']"
            :error-messages="phoneError"
            :hide-details="false"
            label="Insira seu número de telefone"
            outlined
            @input="wrongPhone = false"
            @keydown.enter="handleClickButton"
          />
          <mf-button class="btn-submit-form" color="marca-0" :disabled="disableButton" label="Disparar código" :loading="loading" @click="handleClickButton" />
        </div>
      </div>

      <div v-if="step === 'sms-pin-input'">
        <pin-input ref="pinInput" :error="pinError" @send-code="handlePin" />
        <div class="text-center mt-4">
          <span class="py-2 px-4 link" :class="disabledTimer ? 'disabled-timer' : ''" @click="resendCode">Redisparar SMS {{ secondsText }}</span>
        </div>

        <div class="text-center mt-8 mb-0">
          <span v-if="!dontReceivedSms" class="py-2 px-4 link" @click="dontReceivedSms = true">Não recebi meu SMS</span>
        </div>
      </div>
    </div>

    <back-to-login @go-back="goBack" />
  </div>
</template>

<script>
import { required, minLength, maxLength } from '@vuelidate/validators'
import { notOnlySpace } from '@/helpers/validators'
import { MUTATION_RESEND_CONFIRM_CODE } from '../../graphql'
import { pinInput } from '../../mixins/pin-input'

export default {
  name: 'EmailNotReceived',
  mixins: [pinInput],
  props: {
    hiddenPhone: {
      type: String,
      default: '',
    },
    confirmationToken: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    tryWith: 'email',
  }),
  computed: {
    localConfirmationToken: {
      get() {
        return this.confirmationToken
      },
      set(value) {
        this.$emit('update:confirmationToken', value)
      },
    },
    description() {
      if (this.step === 'try-sms') {
        return `Confirme seu número de telefone <span class="highlited-text">${this.hiddenPhone}</span>, completando-o na campo abaixo:`
      } else if (this.step === 'sms-pin-input') {
        if (this.dontReceivedSms)
          return `Você pode tentar redisparar o SMS ou entrar em contato com o administrador da sua plataforma para solicitar a verificação do seu dispositivo de acesso à
              plataforma Mercafacil.
                <p class="mt-4">Em último caso entre em contato com: suporte@mercafacil.com</p>`
        return `Disparamos um SMS com um código de confirmação para <span class="highlited-text">${this.phone}</span>, para verificar o seu dispositivo de acesso, basta inserir o código recebido abaixo:`
      }
      return ''
    },
  },
  mounted() {
    this.tryWith = 'email'
  },
  methods: {
    trySms() {
      this.clearInterval()
      this.step = 'try-sms'
      this.tryWith = 'sms'
    },
    handlePin(code) {
      this.$emit('remove-error')
      if (code.length < 6) return
      if (this.preventSendCode) return
      this.preventSendCode = true
      this.time = setTimeout(() => {
        this.$emit('send-code', code)
        this.preventSendCode = false
      }, 300)
    },
    async resendCode() {
      if (this.disabledTimer) return
      this.pinError = ''
      if (this.$refs && this.$refs.pinInput) this.$refs.pinInput.code = new Array(6)
      this.loading = true
      try {
        const {
          data: {
            resendUserDeviceConfirmation: { deviceConfirmationToken },
          },
        } = await this.$apollo.mutate({
          mutation: MUTATION_RESEND_CONFIRM_CODE,
          fetchPolicy: 'no-cache',
          variables: {
            deviceConfirmationToken: this.localConfirmationToken,
            phoneNumber: this.phone,
            senderType: 'SMS',
          },
        })
        this.localConfirmationToken = deviceConfirmationToken
        this.step = 'sms-pin-input'
        this.startTimer()
        this.$snackbar({ message: 'Código de verificação enviado para o celular cadastrado.', snackbarColor: 'success' })
      } catch (error) {
        console.log(error)
        if (error.graphQLErrors?.[0]?.message === 'Phone not match') {
          this.$snackbar({ message: 'Telefone não associado a essa conta', snackbarColor: 'error' })
          this.wrongPhone = true
        }
        if (error.graphQLErrors?.[0]?.message === 'User is admin') {
          this.$snackbar({ message: 'Esse usuário é admin.', snackbarColor: 'error' })
        } else if (error.graphQLErrors?.[0]?.message === 'authentication.error.device.request.verification') {
          this.$snackbar({ message: 'Houve um erro ao tentar enviar o codigo novamente', snackbarColor: 'error' })
        }
      }
      this.loading = false
    },
    handleClickButton() {
      if (this.loading || this.disableButton) return
      this.v$.$reset()
      this.v$.$touch()
      if (this.v$?.$error) return

      if (this.step === 'try-sms') {
        this.resendCode()
      }
    },
  },
  validations() {
    return {
      phone: { required, notOnlySpace, minLength: minLength(18), maxLength: maxLength(19) },
    }
  },
}
</script>

<style lang="scss" scoped>
.email-not-received {
  & p {
    color: #4b525a;
    font-size: 16px;
    line-height: 120%;
    text-align: center;
    font-weight: 400;
  }

  & .link-container {
    text-align: center;
  }
}

.password-recover-description {
  color: $cinza-0;
  font-size: 16px;
  line-height: 120%;
}

::v-deep .highlited-text {
  color: $marca-1;
  font-weight: bold;
}

.unset-capitalize {
  text-transform: unset !important;
  letter-spacing: 0.02px !important;
}

.not-my-phone-card {
  border-radius: 8px;
  border: 1px solid $cinza-l60;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.18);
  background-color: $branco;
  p {
    color: $cinza-d10;
    font-size: 16px;
    line-height: 120%;
  }
}

.disabled-timer {
  color: $cinza-l10 !important;
  cursor: not-allowed !important;
}
</style>
